@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;


.button__container {
    margin-right: 1rem;

    &-join {
        border-radius: 10px;
        border: none;
        color: white;
        font-size: 1rem;
        font-family: "kit-rounded-variable", sans-serif;
        font-variation-settings: "wght" 500;
        font-weight: 600;
        height: 2rem;
        justify-content: center;
        width: 4rem;
        background: #dbdbdb;
        border-radius: 44px;
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
        background-color: #c68ae58a;
    

        &:hover {
           
            transform: scale(1.2);
        }
        &:active {
            background: #c5c5c5;
            box-shadow: inset -6px 6px 12px #bababa, inset 6px -6px 12px #fcfcfc;
            transform: scale(1);
        }
    }

    &-input {
        border-radius: 10px;
        align-content: center;
        align-items: center;
        border: none;
        color: white;
        font-size: 1rem;
        font-family: "kit-rounded-variable", sans-serif;
        font-variation-settings: "wght" 500;
        font-weight: 600;
        height: 2rem;
        justify-content: center;
        width: 5rem;
        background: #dbdbdb;
        border-radius: 44px;
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
        background-color: #4c86dea8;
    

        &:hover {
      
            transform: scale(1.2);
        }

        &:active {
            background: #c5c5c5;
            box-shadow: inset -6px 6px 12px #bababa, inset 6px -6px 12px #fcfcfc;
            transform: scale(1);
        }
    }
}