@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

.controls {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 14px;
    height: 80px;
    margin-top: 10px;
    gap: 10px;
    border-radius: 44px;
    gap: 10px;
    background: #dbdbdb;
    color: #5C667E;
    box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;


    &__mic {
        color: $lightblue;
    }

    &__button__live {
        color: $lightpink;
    }

    &__button__back {
        background-color: $lightpink;
    }

    .css-zddlty-MuiButtonBase-root-MuiButton-root {
        color: $white;
        display: flex;
        font-family: "kit-rounded-variable", sans-serif;
        font-variation-settings: "wght" 500;
        font-size: 1rem;
        font-weight: 600;
        color: darkgrey;
        text-decoration: none;
        border-radius: 44px;
        background: #dbdbdb;
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
        width: 8rem;
        height: 3rem;
        align-items: center;
        justify-content: center;
        transition: box-shadow 0.2s, background 0.2s, transform 0.2s;

        &:hover {}
    }

    .MuiButton-root {
        & .MuiSvgIcon-root {
            margin-right: 5px;
            color: $lightpink;

            &:hover {}



        }

        img {
            height: 20px;
            width: 20px;
            margin-left: 5px;
        }

        @include secondary-font;
        color: $slate;
        font-size: .8rem;

    }

    .leave {


        &:hover {}
    }

    .mui__button {
        color: $white;
        display: flex;
        font-family: "kit-rounded-variable", sans-serif;
        font-variation-settings: "wght" 500;
        font-size: 1rem;
        font-weight: 600;
        color: darkgrey;
        text-decoration: none;
        border-radius: 44px;
        background: #dbdbdb;
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
        width: 8rem;
        height: 3rem;
        align-items: center;
        justify-content: center;
        transition: box-shadow 0.2s, background 0.2s, transform 0.2s;




    }
}

.text__label{

    @include secondary-font;
    
}