@use '../src/partials/globals' as *;
@use '../src/partials/mixins' as *;
@use '../src/partials/typography' as *;
@use '../src/partials/variables' as *;


.wrap {

    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    padding-top: 4rem;
    max-width: 1200px;


}

// sign in container
.cl-internal-17ydaof {
    box-sizing: inherit;
    display: flex;
    flex-flow: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 2rem;
    will-change: transform, opacity, height;
    border-radius: 1rem;

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 200ms;
    border-radius: 44px;
    background: #dbdbdb;
    box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;

    border: 1px solid transparent;
    width: 25rem;
    max-width: calc(100vw - 5rem);
    margin: 0px 1.75rem;
    padding: 2.375rem 2rem 3rem;
    @include secondary-font;

}

.cl-internal-phfxlr {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;


}

//  sign in clerk flag
.cl-internal-b3fm6y {

    display: none;
}

// sign in buttons for auth
.cl-internal-q966dm {

    border-radius: 44px;
    background: #dbdbdb;
    box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;

}

// input field
.cl-internal-mdg29y {
    border-radius: 44px;
    background: #dbdbdb;
    box-shadow: inset -9px 9px 18px #bababa, inset 9px -9px 18px #fcfcfc;
    color: #5C667E;

}



// sign in button
.cl-internal-1fsg6zy {

    border-radius: 44px;

    box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;

    &:active {
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
    }


}


.cl-internal-1icenji {
    border-radius: 44px;
    background: #dbdbdb;
    box-shadow: inset -9px 9px 18px #bababa, inset 9px -9px 18px #fcfcfc;
    color: #5C667E;
   



}

.cl-internal-nzv1hj {

    border-top-left-radius: 44px;
    border-bottom-left-radius: 44px;
}

.cl-internal-omxbjf {

    border-top-right-radius: 44px;
    border-bottom-right-radius: 44px;
    box-shadow: inset -9px 9px 18px #bababa, inset 9px -9px 18px #fcfcfc;
    background: #dbdbdb;
    

}

