@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

.rightBox {
    border-radius: 10px;
    width: 30%;
    min-width: 250px;
    padding: 1.5rem 1rem;
    @include secondary-font;
    border-radius: 44px;
    background: #dbdbdb;
    color: $slate;
    box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;

    &__optionView {
        height: 100%;
    }

    &__head {
        width: 100%;
        text-align: left;
        height: fit-content;
    }

    &__head .selected {
        color: $lightblue;
    }

    &__head span {
        font-weight: bold;
        color: $slate;
        cursor: pointer;
    }

    &__head span:nth-child(1) {
        margin-right: 10px;
    }

    &__chats {
        height: calc(100% - 60px);
        overflow: scroll;
        padding: 10px 0;
        ms-overflow-style: none;
        scrollbar-width: none;
        /* Firefox */
    }

    &__chats::-webkit-scrollbar {
        display: none;
    }

    &__optionView form input {
        border: none;
        width: 100%;
        height: 40px;
        background-color: $offwhite;
        border-radius: 44px;
        padding: 0 10px;
        box-sizing: border-box;
    }

    &__optionView form input:focus {
        outline: none;
    }

    &__participants {
        padding: 44px 0;
        height: 100%;
        overflow: scroll;
        // ms-overflow-style: none;
        scrollbar-width: none;
        /* Firefox */
    }

    &__participants::-webkit-scrollbar {
        display: none;
    }

    &__participant {
        text-align: left;
        text-align: left;
        background-color: #e9ebef;
        padding: 10px;
        border-radius: 44px;
        margin-bottom: 10px;
    }

    &__participant p {
        font-size: 14px;
    }
}

.send__message {
    color: $black;

    &__placeholder {
        color: $black;

    }

    &__emoji {
        background-color: #F4F5F0;
        border-radius: 44px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}