@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;


.join__room__root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 40rem;
    margin: 1rem auto;
    padding: 2rem;


    &__title {

        @include secondary-font;
        font-size: 2rem;
    }
}

.join__room {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 40rem;
    gap: .3rem;
    padding: 2.5rem 1.25rem;
    border-radius: 10px;
    height: 36rem;
    margin-top: 1rem;
    border-radius: 44px;
    background: #dbdbdb;
    box-shadow: -9px 9px 18px #bababa,
        9px -9px 18px #fcfcfc;
}

.join__room__w {
    height: 45px;
    border: none;
    outline: none;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    @include secondary-font;
    border-radius: 44px;
    background: #dbdbdb;
    box-shadow: inset -9px 9px 18px #bababa,
        inset 9px -9px 18px #fcfcfc;

    &__button {
        height: 5rem;
        border: none;
        outline: none;
        margin-top: auto;
        width: 100%;
        color: darkgrey;
        font-family: "BATT";
        font-size: 3rem;
        line-height: 4rem;
        font-weight: 400;
        letter-spacing: 0.05rem;
        border-radius: 44px;
        background: linear-gradient(225deg, #eaeaea, #c5c5c5);
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
        transition: box-shadow 0.2s, background 0.2s; 
        background-image: linear-gradient(to right top, #4c86de, #7c88e4, #a389e7, #c68ae5, #e58be0);

        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    
        

        &:active {
            background: linear-gradient(225deg, #c5c5c5, #eaeaea); 
            box-shadow: inset -6px 6px 12px #bababa, inset 6px -6px 12px #fcfcfc; 
            background-image: linear-gradient(to right top, #4c86de, #7c88e4, #a389e7, #c68ae5, #e58be0);
            color: $white;
        
        }

        &:hover {
            
        }

        &.active {
            color: $orange;


        }
    }
}

.label_room-name {
    text-align: start;
    @include primary-l;
    background-image: linear-gradient(to right top, #4c86de, #7c88e4, #a389e7, #c68ae5, #e58be0);

    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;



    margin-bottom: 1.2rem;
}

.label_room {
    text-align: start;
    @include primary-l;
    margin-top: 3.5rem;
    margin-bottom: 1.2rem;
    background-image: linear-gradient(to right top, #4c86de, #7c88e4, #a389e7, #c68ae5, #e58be0);

    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

}


.join {

    input,
    select {
        padding: 0 10px;

        &::placeholder {
            color: hsl(223, 3%, 56%);

        }
    }

    button {

        &:hover {
            background-color: inherit;
        }

        &:active {
            background-color: rgb(201, 205, 220);
        }
    }
}