@use './partials/globals' as *;
@use './partials/mixins' as *;
@use './partials/typography' as *;
@use './partials/variables' as *;


body {
  margin: 0;
  font-family: 'BATT';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $cloud;
  color: $white;
  animation: colorChange 20s infinite;

}