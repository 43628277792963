@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

video {
    border-radius: 10px;

    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.video {
    border-radius: 10px;
    width: calc(50% - 10px);
    height: unset;
}