@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $graphite-modal;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.modal__auth {
    height: 50%;
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: $black;
    

    &-hello {
        @include header;
        padding-top: 3rem;
        border-bottom: 3px $white solid;
    }

    @include tablet {
        width: 100%;
        height: 50%;
        border-radius: .25rem;

    }

    &-close {
        justify-content: flex-end;
        margin-right: 1rem;
       
    }

    &-box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &-container {
        display: flex;
        @include pad-mob;
        height: 50%;
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
       


        @include tablet {
            height: 60%;
            padding-left: 3rem;
            padding-right: 3rem;


        }
    }

    &-title {
        @include mob-h1;
        padding-bottom: 1rem;

        @include tablet {
            @include desk-h1
        }
    }

    &-text {
        @include mob-p2;

        @include tablet {
            @include desk-p2
        }
    }


    &__button__container {
        @include pad-mob;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        padding: 3rem;
       

        @include tablet {
            justify-content: center;
            padding-left: 3rem;
            padding-right: 3rem;
        }

        &-google {
            // background-color: ;
            
        }

        &-action {
            color: $slate;
            width: 8.5rem;
            background-color: $white;
            border: 1px solid $cloud;
            @include button-mob;
            @include mob-h3;

            @include tablet {
                @include desk-h3;
                width: 12.5rem;
                
            }
        }
    }
}