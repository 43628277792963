@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;



// ProfilePage.scss
.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 40rem;
    gap: 0.3rem;
    padding: 2.5rem 1.25rem;
    border-radius: 10px;
    margin: 0 auto;
    height: 63vh;
    margin-top: 4rem;
    border-radius: 44px;
    background: $cloud;
    box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;


    &__title {
        color: $white;
        text-align: center;
        margin-bottom: 2rem;
        font-size: 2.5rem;
        
        
        @include secondary-font;
       


    }

    &__image-container {

display: flex;
flex-direction: column;
align-items: center;

    }
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 1rem;
    }

    &__avatar {
        border-radius: 50%;
        border: 3px solid $white;
        width: 120px;
        height: 120px;
        object-fit: cover;
        cursor: pointer;
        margin-bottom: 1rem; 
    }

    &__input {
        &--file {
            display: none; 
        }

        &--text,
        &--email {
            height: 45px;
            border: none;
            outline: none;
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            font-family: "kit-rounded-variable", sans-serif;
            font-variation-settings: "wght" 500;
            border-radius: 44px;
            background: #dbdbdb;
            box-shadow: inset -9px 9px 18px #bababa, inset 9px -9px 18px #fcfcfc;
        
        }
    }

    &__button {
        border-radius: 10px;
        align-content: center;
        align-items: center;
        border: none;
        color: darkgrey;
        font-size: 1rem;
        @include secondary-font;
        height: 4rem;
        justify-content: center;
        width: 8rem;
        background: #dbdbdb;
        border-radius: 44px;
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
        margin-top: 2rem;

        &--upload {
            margin-bottom: 1rem; // Margin to separate from the next input field
        }

        &:disabled {
            // background-color: $slate;
        }
    }
}