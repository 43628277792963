@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

.header__row {

    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 2rem;
    border-radius: 10px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding-left: 4.5rem;
    padding-right: 4.5rem;


    &__links {
        display: flex;
        width: 22rem;
        align-items: center;
        justify-content: space-between;
        color: $white;

        &-home {
            display: flex;
            @include secondary-font;
            font-size: 1rem;
            font-weight: 600;
            color: $white;
            text-decoration: none;

            @include header-shadow;
            border-radius: 10px;

            width: 6rem;
            height: 3rem;
            align-items: center;
            justify-content: center;

            &:hover {
                color: $lightpink;
                border-radius: 10px;
                transform: scale(1.2);
            }
        }

        &-profile {
            display: flex;
            @include secondary-font;
            font-size: 1rem;
            font-weight: 600;
            color: $slate;
            text-decoration: none;
            border-radius: 44px;
            background: #dbdbdb;
            box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
            width: 6rem;
            height: 3rem;
            align-items: center;
            justify-content: center;
            transition: box-shadow 0.2s, background 0.2s, transform 0.2s;


            &:hover {
                background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                transform: scale(1.2);
            }

            &:active {
                background: #c5c5c5;
                box-shadow: inset -6px 6px 12px #bababa, inset 6px -6px 12px #fcfcfc;
                transform: scale(1);
                background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
            }
        }
    }

    &__title {
        color: $white;
        font-size: 3rem;
        line-height: 3rem;
        font-weight: 400;
        letter-spacing: 0.05rem;
        transition: color 0.3s ease-in-out;
        padding: 2rem;
        -webkit-backdrop-filter: blur(9.4px);
        border-radius: 44px;
        background: #dbdbdb;
        box-shadow: -21px 21px 18px #bababa, 26px -26px 29px #fcfcfc;
        background-image: linear-gradient(to right top, #4c86de, #7c88e4, #a389e7, #c68ae5, #e58be0);

        &:hover {

            color: $offwhite;
            border-radius: 44px;
            transform: scale(1.2);
        }
    }
}