@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

.beat__button {

    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
    border-radius: 10px;
    flex-direction: column-reverse;
    align-content: center;
    align-items: center;

    &-bg {
        background: none;
        border: none;
        @include secondary-font;
        color: $slate;
        font-size: .8rem
    }

    &-button {
        display: flex;
        font-family: "kit-rounded-variable", sans-serif;
        font-variation-settings: "wght" 500;
        font-size: .8rem;
        font-weight: 500;
        color: #5C667E;
        text-decoration: none;
        border-radius: 44px;
        background: #dbdbdb;
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
        width: 10rem;
        height: 3rem;
        align-items: center;
        justify-content: center;
        transition: box-shadow 0.2s, background 0.2s, transform 0.2s;


        &:hover {
            background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            transform: scale(1.2);
        }

        &:active {
            background: #c5c5c5;
            box-shadow: inset -6px 6px 12px #bababa, inset 6px -6px 12px #fcfcfc;
            transform: scale(1);
            background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }


    }
}