// Primary Colors

$white: #FFFFFF; // ***** Used for Global white color *****
$blue: #0000FF; // PANTONE 2728 C
$green: #00FF7F;
$offwhite: #F4F5F0; // PANTONE 11-0601 TCX
$black: #101820; // PANTONE Black 6 C
$justBlack: black;
$aqua: #B9DDDA;
$fuchsia: #D62A8C;
$purple: #971A92;
$yellow: #C7D110;
$orange: #FA8057;
$grey: darkgrey;

$lightblue:#4C86DE;
$lightpink: #E58BE0;

// Secondary Colors
$indigo: #2E66E5; // ***** Used for Interactive elements *****
$slate: #5C667E; // ***** Used for Table column headers & placeholder text *****
$cloud: #dbdbdb; // ***** Used for Outlines, dividers, borders, table sorting icon *****

// Background Colors
$graphite: #232940; // ***** Used for Top navigation header background, CTA button hover state background ******

$graphite-modal: #232940c1;


// Supporting Colors


// Breakpoints

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1280px;




$btn-ht-dt: 2.375rem; // ***** Desktop & tablet button height ******
$btn-ht-mb: 2.25rem; // ***** Mobile button height *****

// Padding variable
$btn-pd: 1rem; // ***** Button horizontal padding *****

// Border radius variable
$btn-rd: 1.25rem; // ***** Button border radius *****

// Spacing variable
$link-icn-spc: .25rem; // ***** Text link icon spacing *****


// ***********FORM FIELDS*************************** //


// Height variables
$ff-ht-dt: 2.375rem; //  *****  Desktop & tablet form field height ***** 
$ff-ht-mb: 2.25rem; //  *****  Mobile form field height ***** 

// Padding and spacing variables
$ff-pd: 1rem; //  *****  Form field horizontal padding  ***** 
$ff-spc: .25rem; //  *****  Spacing between label and form field ***** 

// Border radius variable
$ff-rd: 1.25rem; //  *****  Form field border radius ***** 


// ***********TAGS*************************** //


// Tag height variables
$tag-ht-dt: 1.625rem; //  *****  Desktop & tablet tag height *****     
$tag-ht-mb: 1.5rem; //   ***** Mobile tag  height ***** 

// Padding variable
$tag-pd: .5rem; //   ***** Tag horizontal padding ***** 

// Tag background color variables
$tag-bg-out: rgba(201, 69, 21, 0.07); // ***** Out of Stock tag background color ***** 
$tag-bg-in: rgba(21, 132, 99, 0.07); // ***** In Stock tag background color  ***** 

// Border radius variable
$tag-rd: 1.25rem; //  ***** Tag border radius ***** 


// ***********SELECTION CONTROLS*************************** //


// Spacing variables
$spc-lbl-rad: .25rem; // ***** Space between label and radio button group *****
$spc-rad-txt: .5rem; // ***** Space between radio button and text ****