@use '../partials/variables' as *;
@use '../partials/typography' as *;
@use '../partials/mixins' as *;




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;


}

#root {
    height: 130%;
    width: 100%;
}