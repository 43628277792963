@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;


.room {
    margin: auto;
    padding: 2rem;
    display: flex;
    width: 95vw;
    height: 100%;
    border-radius: 10px;
    gap: 10px;


    &__stream-space {
        width: 69%;
        height: 100%;

    }


    &__stream-name {
        display: flex;
        align-items: center;
        @include secondary-font;
        font-size: 2rem;
        padding-top: 2rem;
        justify-content: center;


    }

    &__button {
        display: flex;
        @include secondary-font;
        font-size: 1rem;
        font-weight: 600;
        color: $white;
        text-decoration: none;

        @include header-shadow;
        border-radius: 10px;

        width: 6rem;
        height: 3rem;
        align-items: center;
        justify-content: center;

        &:hover {
            color: $green;
            border-radius: 10px;
            transform: scale(1.2);

        }
    }

    &__url__links {

        display: flex;
        flex-direction: column;
    }
}

.liveStream {
    border-radius: 10px;
    height: calc(100% - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    overflow: hidden;
}