@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;


.stream {
    border-radius: 10px;
    height: 45rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    overflow: hidden;

    &__tile {
        width: 100%;
        height: 100%;

    }

}