@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;


.sign__out {

    display: flex;
    @include secondary-font;
    font-size: 1rem;
    font-weight: 600;
    color: $slate;
    text-decoration: none;
    border-radius: 44px;
    background: #dbdbdb;
    box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
    width: 6rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.2s, background 0.2s, transform 0.2s;
    border: none;


    &:hover {
        background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        transform: scale(1.2);
    }

    &:active {
        background: #c5c5c5;
        box-shadow: inset -6px 6px 12px #bababa, inset 6px -6px 12px #fcfcfc;
        transform: scale(1);
        background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }
}