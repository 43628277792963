.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: -1;
}

.code-illustration {
    z-index: -1;
    position: absolute;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    opacity: 0.7;
    transform: translateY(-50px);
    animation: code-falling 5s linear infinite;
    clip-path: polygon(50% 0%,
            61.8% 38.2%,
            100% 50%,
            61.8% 61.8%,
            50% 100%,
            38.2% 61.8%,
            0% 50%,
            38.2% 38.2%);

}
@keyframes code-falling {
    0% {
        transform: translateY(-50px);
    }

    100% {
        transform: translateY(calc(100vh + 50px));
    }
}