@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

.room__page__list {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 40rem;
    gap: 0.3rem;
    padding: 2.5rem 1.25rem;
    margin: 0 auto;
    height: 63vh;
    margin-top: 4rem;
    border-radius: 44px;
    background: #dbdbdb;
    box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;
    flex: 1;
    overflow-y: scroll;




    &-button {

        display: flex;
        justify-content: center;
        width: 100%;
        border-radius: 10px;
        flex-direction: column-reverse;
        align-content: center;
        align-items: center;
        border: none;
        color: $slate;
        display: flex;
        font-size: 1rem;
        @include secondary-font;
        font-weight: 600;
        height: 5rem;
        justify-content: center;

        margin-bottom: 1rem;
        text-decoration: none;
        width: 10rem;
        background: $cloud;
        border-radius: 44px;
        box-shadow: -9px 9px 18px #bababa, 9px -9px 18px #fcfcfc;






        &:hover {
            background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;

            transform: scale(1.2);
        }

        &:active {
            background: #c5c5c5;
            box-shadow: inset -6px 6px 12px #bababa, inset 6px -6px 12px #fcfcfc;
            transform: scale(1);
            background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }
    }

    &-input {
        height: 4rem;
        margin: 1.5rem;
        border: none;
        outline: none;
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        @include secondary-font;
        border-radius: 44px;
        background: $cloud;
        box-shadow: inset -9px 9px 18px #bababa, inset 9px -9px 18px #fcfcfc;
        color: $slate;

        &::placeholder {
            @include secondary-font;
            color: $grey;
            font-size: bold;

        }
    }

}


.text__error{

    @include secondary-font;
    color: red;
    font-size: 1rem;
}