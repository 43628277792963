@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

.room__list {
    align-items: center;
    display: flex;
    flex-direction: column;
    @include secondary-font;
    gap: 0.3rem;
    height: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    max-height: 100%;
    max-width: 40rem;
    overflow-y: scroll;
    width: 100%;


    &__item {
        display: flex;
        justify-content: space-between;
        color: $slate;
        width: 70%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-radius: 44px;
        box-shadow: -9px 9px 18px #bababa,
            9px -9px 18px #fcfcfc;
        padding: 1rem;
        align-items: center;
        // background-image: linear-gradient(to right top, #dfd1db, #f4a0bc, #9474e7, #a9a9a9, #e58be0);
        // -webkit-background-clip: text;
        // background-clip: text;
        // color: transparent;
    }
}