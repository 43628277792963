@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 44px;
}

.video {
    width: calc(50% - 10px);
    height: unset;
}

.broadcaster {

    font-size: 3rem;
    color: $grey;
}