@use '../../partials/globals' as *;
@use '../../partials/mixins' as *;
@use '../../partials/typography' as *;
@use '../../partials/variables' as *;

.message {
    width: fit-content;
    text-align: left;
    max-width: 90%;

    span {
        font-size: 12px;
        color: rgb(191, 196, 199);
    }

    p {
        background-color: hsl(228, 10%, 90%);
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 14px;
        overflow: hidden;
        margin-bottom: 5px;
    }
}

.myMessage {
    margin-left: auto;
    text-align: right;

    p {
        background-color: $lightpink;
        color: $graphite;
    }
}